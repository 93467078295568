import Typography from 'typography';
import Wordpress2016 from 'typography-theme-wordpress-2016';
import palette from './palette';

Wordpress2016.overrideThemeStyles = () => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    a: {
      boxShadow: 'none',
      color: palette.purpleNavy,
    },
    'a:hover': {
      boxShadow: '0 1px 0 0 currentColor',
    },
    blockquote: {
      borderColor: palette.wildBlueYonder,
    },
    'artile ul, article ol': {
      marginLeft: typography.rhythm(1),
    },
  };
};

delete Wordpress2016.googleFonts;

const typography = new Typography(Wordpress2016);

// Hot reload typography in development.
/* istanbul ignore next */
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
