import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { rhythm } from '../utils/typography';

import styles from '../utils/override.module.css';

const Socials = () => {
  const data = useStaticQuery(graphql`
    query SocialsQuery {
      site {
        siteMetadata {
          social {
            twitter
            facebook
            linkedin
            instagram
          }
        }
      }
    }
  `);

  const { social } = data.site.siteMetadata;

  return (
    <ul className={styles.socials}>
      <li style={{ marginRight: rhythm(0.25) }}>
        <a href={`https://facebook.com/${social.facebook}`} target="_black" rel="noopener noreferrer">
          facebook
        </a>
      </li>
      <li>•</li>
      <li style={{ marginRight: rhythm(0.25), marginLeft: rhythm(0.25) }}>
        <a href={`https://twitter.com/${social.twitter}`} target="_black" rel="noopener noreferrer">
          twitter
        </a>
      </li>
      <li>•</li>
      <li style={{ marginRight: rhythm(0.25), marginLeft: rhythm(0.25) }}>
        <a href={`https://linkedin.com/in/${social.linkedin}`} target="_black" rel="noopener noreferrer">
          linkedin
        </a>
      </li>
      <li>•</li>
      <li style={{ marginLeft: rhythm(0.25) }}>
        <a href={`https://instagram.com/${social.instagram}`} target="_black" rel="noopener noreferrer">
          instagram
        </a>
      </li>
    </ul>
  );
};

export default Socials;
